import { DialogService }                 from 'aurelia-dialog';
import { inject }                        from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { PLATFORM }                      from 'aurelia-pal';
import { ListBMTests }                   from 'modules/bituminous-mixtures/mixtures/bm-tests';
import { FilterFormSchema }              from 'modules/bituminous-mixtures/mixtures/bm-tests/filter-form-schema';
import { BMTestsRepository }             from 'modules/bituminous-mixtures/mixtures/bm-tests/services/repository';
import { CommentsListingModal }          from 'modules/core/custom-components/comments/listing-modal';
import { AdditionalInfoListingModal }    from 'modules/core/listing-modals/additional-info-listing-modal';
import { SelectedBulletinsListingModal } from 'modules/core/listing-modals/selected-bulletins-listing-modal';
import { Sector }                        from 'modules/management/models/sector';
import { AppContainer }                  from 'resources/services/app-container';
import { Downloader }                    from 'resources/services/downloader';

@inject(AppContainer, BMTestsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListBMLabTests extends ListBMTests {

    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer, repository, i18N, downloader, filterFormSchema, dialogService);

        this.type     = 'lab';
        this.sectorId = Sector.BITUMINOUS_MIXTURES_LAB;
    }

    columns() {
        return [
            {
                data:  'test_number',
                name:  'test_numbers.number',
                title: 'form.field.sample-number',
            },
            {
                data:  'concession_name',
                name:  'concession_translations.description',
                title: 'form.field.concession',
            },
            {
                data:  'highway_name',
                name:  'highway_translations.designation',
                title: 'form.field.highway',
            },
            {
                data:  'intervention_type_name',
                name:  'intervention_type_translations.designation',
                title: 'form.field.intervention-type',
            },
            {
                data:  'lot_intervention_name',
                name:  'lot_intervention_translations.designation',
                title: 'form.field.lot',
            },
            {
                data:       'entity_name',
                name:       'entity_translations.name',
                title:      'form.field.entity',
                searchable: false,
                orderable:  false,
                    type:       'entityTeam',
            },
            {
                data:  'external_reference',
                name:  'test_headers.external_reference',
                title: 'form.field.external-ref',
            },
            {
                data:    'material_type_name',
                name:    'material_type_translations.name',
                title:   'form.field.material-type',
                display: false,
            },
            {
                data:    'layer_name',
                name:    'bm_layer_translations.name',
                title:   'form.field.layer',
                display: false,
            },
            {
                data:    'application_name',
                name:    'bituminous_application_translations.name',
                title:   'form.field.application',
                display: false,
            },
            {
                data:       'application_locations',
                name:       'bm_test_application_locations.lot_construction_id',
                title:      'form.field.application-locations',
                type:       'custom-cell',
                searchable: false,
                orderable:  false,
                viewModel:  PLATFORM.moduleName('modules/bituminous-mixtures/mixtures/bm-tests/custom-cells/application-locations/index'),
            },
            {
                data:    'date',
                name:    'test_headers.date',
                title:   'form.field.collection-date',
                display: false,
            },
            {
                data:    'approved_working_formula_name',
                name:    'bm_approved_working_formulas.number',
                title:   'form.field.approved-working-formula',
                display: false,
            },
            {
                data:    'lot_construction_name',
                name:    'lot_construction_translations.designation',
                title:   'form.field.location',
                display: false,
            },
            {
                data:    'track_name',
                name:    'tracks.name',
                title:   'form.field.track',
                display: false,
            },
            {
                data:    'branch_name',
                name:    'branch_translations.name',
                title:   'form.field.branch',
                display: false,
            },
            {
                data:    'edge_name',
                name:    'edge_translations.name',
                title:   'form.field.edge',
                display: false,
            },
            {
                data:       'formatted_pk',
                name:       'formatted_pk',
                title:      'form.field.pk',
                orderable:  false,
                searchable: false,
                display:    false,
            },
            {
                data:  'created_by',
                name:  'user_entity_team_lots.identifier',
                title: 'form.field.created-by',
                type:  'userEntityTeamLot',
            },
            {
                data:            'created_at',
                name:            'bm_tests.created_at',
                title:           'form.field.created-at',
                valueConverters: [
                    {
                        name: 'dateTimeFormat',
                    },
                ],
            },
            {
                data:    'observations',
                name:    'test_headers.observations',
                title:   'form.field.observations',
                display: false,
            },
            {
                data:       'additional_info',
                name:       'additional_info',
                title:      'form.title.additional-info',
                type:       'custom-cell',
                icon:       'icon-info3',
                show:       (row) => row.has_additional_information,
                action:     (row) => this.openModal({ relatableId: row.test_header_id }, AdditionalInfoListingModal),
                viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                orderable:  false,
                searchable: false,
            },
            {
                data:       'comments',
                name:       'comments',
                title:      'form.title.comments',
                type:       'custom-cell',
                icon:       'icon-comments',
                show:       (row) => row.has_comments,
                action:     (row) => this.openModal({ relatableId: row.test_header_id }, CommentsListingModal),
                viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                orderable:  false,
                searchable: false,
            },
            {
                data:       'cover_page',
                name:       'cover_page',
                title:      'form.field.cover-page',
                type:       'custom-cell',
                icon:       'icon-file-pdf',
                action:     (row) => this.exportToPdf(row),
                viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/action-icon/index'),
                orderable:  false,
                searchable: false,
            },
            {
                data:          'validated_by',
                name:          'user_entity_team_lots.identifier',
                title:         'form.field.validated',
                type:          'custom-cell',
                fullDataModel: 'validated_by',
                viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                orderable:     false,
                searchable:    false,
            },
            {
                data:       'verified',
                name:       'user_entity_team_lots.identifier',
                title:      'form.field.verified',
                type:       'custom-cell',
                viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/verified/index'),
                orderable:  false,
                searchable: false,
            },
            {
                data:       'process_status',
                name:       'process_status',
                title:      'form.field.process-status',
                type:       'custom-cell',
                action:     (row) => this.openModal({ relatableId: row.test_header_id }, SelectedBulletinsListingModal),
                viewModel:  PLATFORM.moduleName('modules/core/custom-listing-cells/processing-status/index'),
                orderable:  false,
                searchable: false,
            },
        ];
    }

}
